.mapael .map {
  position: relative;
  top: -70px;
}

.mapael .mapTooltip {
  position: absolute;
  left: --positionX !important;
  top: --positionY !important;
  color: var(--primary);
  font-weight: bold;
  font-size: small;
  background-color: #fff;
  opacity: 0.7;
  filter: alpha(opacity=70);
  border-radius: 10px;
  padding: 10px;
  z-index: 1000;
  max-width: 200px;
  display: none;
}

.mapael .row {
  justify-content: center;
}

.city-list .sub-title {
  color: var(--orange);
  font-weight: 500;
}

.city-list .media-body {
  margin-left: 5px;
  font-weight: 300;
}

.city-list .media-left {
  color: var(--secondary);
}

.city-list ul li {
  position: relative;
  width: 30%;
  display: inline-block;
  vertical-align: top;
  padding-right: 24px;
  margin: 0 0 6px;
  line-height: 24px;
}

@media only screen and (max-width: 887px) {
  .city-list ul li {
      width: 48%;
  }
}

@media only screen and (max-width: 767px) {
  .city-list {
    margin-left: 25%;
  }  
}

@media only screen and (max-width: 479px) {
  .city-list ul li {
      width: 100%;
  }
}

.city-list ul.active li, .city-list ul.active .media-left, .city-list ul.active .media-body {
  color: var(--primary);
  font-weight: bold;
}