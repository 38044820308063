
/* Footer  Section Start  css*/
footer{
    background:var(--primary);
    padding: 25px 0;

    
}
footer img {
    display: block;
    margin: auto;
}

footer p{
    color: var(--white);
    margin: auto;
    padding-top: 20px;
}
.footer-menu{
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    align-items: center;
    justify-content: flex-end;
   
}
.footer-menu a{
    color: var(--white);
    background-color: transparent;
}
.footer-menu a:first-child:before {
    display: none;
}
.footer-menu a:hover{
    color: var(--primary);
}
.footer-menu a:before {
    content: "";
    position: absolute;
    left: 0;
    top: 13px;
    background: #fff;
    width: 2px;
    height: 15px;
}