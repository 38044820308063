 /* [Table of content]
=================
[01] Default css
[03] Navbar
[06] Hero Section
[07] Subscribe Section
[08] Welcome Section
[09] About us Section
[10] Our Services Section
[11] Team Section 
[12] Portfolio Section
[13] Marketing Section
[14] Expert Feadback Section
[15] Our Latest Pricing Section
[16] Marketing Section
[17] Work With Us  Section
[18] Contact Us Section
[19] Footer  Section

====================
[ End table of content]
====================*/

  /* Default css */
html {
  height: 100%;
}

body {
  font-size: 16px;
  color: var(--light);
  background: var(--white);
  font-family: 'Roboto', sans-serif;
  line-height: 1.321em;
  letter-spacing: 0.009em;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
   text-rendering: optimizeLegibility;

}

* {
  margin: 0px;
  padding: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style-type: none;
   
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

a {
  display: inline-block;
  outline: 0!important;
  text-decoration: none!important;
  transition: .4s;
}

a:focus {
    outline: none;
    box-shadow: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    font-weight: 700;
    color: var(--black);  
    line-height: 1.5;
    word-wrap: break-word;      
    letter-spacing: 1px;
    
}

h1 {
    font-size: 42px;
    /* = 42px */
    margin-bottom: .5em;
}

h2 {
    font-size: 36px;
    /* = 36px */
    margin-bottom: .75em;
}

h3 {
    font-size: 30px;
    /* = 30px */
    margin-bottom: .857em;
}

h4 {
    font-size: 24px;
    /* = 24px */
    margin-bottom: .5rem;
}

h5 {
    font-size: 18px;
    /* = 18px */
    margin-bottom: 1.125em;
}

h6 {
    font-size: 16px;
    /* = 16px */
    margin-bottom: 1.285em;
}

p {
    margin: 0 0 20px 0;    
  
    font-size: 15px;  
    font-weight: 300;
    color: var(--light);
    line-height: 1.5;
}
button:focus{
  outline: none;
}
.grapping{
  padding: 100px 0;
}
.btn-secondary{
  background: var(--white);
  border-color: var(--white);
  font-weight: 500;
  color:var(--black);
 
}
.btn {
  font-weight: 700;
  font-size: 15px;
  border: none;
  padding: 10px 33px;
  line-height: 1.5;
  text-transform: capitalize;
  border-radius: 50px;
  letter-spacing: 0.5px;
}

.title-box{
  text-align: center;
  margin-bottom: 50px;
  margin-top: -8px;
  
}
.title-box h2 {
  font-size: 30px;
  
  margin-bottom: 15px;
}
.title-box p {
  font-size: 17px;
  max-width: 767px;
  margin: 0 auto ;
  line-height: 1.5;
  font-weight: 400;
  padding: 0 10px;  
}
.sub-title {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0 0 15px;
}
.sub-title span{
  color: var(--primary);
}
.white-bg{

  box-shadow: 7px 5px 30px rgba(72,73,121,.15);
  padding: 25px;
  background: var(--white);;
  position: relative;
  transition: .4s;
}
.coman-box i {
  font-size: 50px;
  color: var(--secondary);
  background: var(--grey);
 
}

.coman-box h4 {
  font-size: 20px;  
  margin-bottom: 10px;
  margin-top: 15px;
}
.coman-box p {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.8;
  background: var(--grey);
  margin-bottom: 0;
}

 /* End  Default css */
/* Page Start css*/
/* Navbar separate CSS file Name Tdsheader.css   */
/* Hero Section Star separate CSS file Name Tdsbanner.css   */
/*Subscribe Section Start css*/
.subscribe-box{
  background: var(--primary);
  padding: 50px 0;   
}
/* About us Section Start separate CSS file Name Tdsaboutslider.css   */
/*Our Services Section Start css*/
.ourservice{
  background: var(--bggrey);
}
.ourservice-box{
  margin-bottom: 15px;
  margin-top: 15px;
  transition: 0.5s;
}
.ourservice-box:hover{
  
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    transition: 0.5s;
    box-shadow: 7px 5px 30px rgba(72,73,121,.15);

}
.locations {
  padding-bottom: 0px;
}
/*Team Section Start css*/
.expert-team{
  background: var(--bggrey);
}
.expert-team .hiring {
  margin-top: 85px;
}
@media (max-width: 575px) {
  .expert-team .hiring {
    margin-top: 0;
  }
}
.expert-team .hiring p {
  margin-bottom: 10px;
}
.expert-team .hiring p b{
  color: var(--secondary);
}
.team-box {
  box-shadow: 7px 5px 30px rgba(72,73,121,.15);
  background: var(--primary);
  position: relative;
  transition: .4s;
  padding-top: 01px;
  padding: 50px 25px 25px;
  margin-top: 85px;
  border: 1px solid var(--primary);
  
}
.team-box p{
  margin-bottom: 20px;
  font-size: 13px;
}
.team-box p.team-member-title {
  font-weight: 100;
}
.team-box h4 {
  margin-bottom: 0!important;
}
.team-img {
  width: 160px;
  height: 160px;
  border-radius: 100%;
  border: 1px solid var(--primary);
  margin: -131px auto 0;
  

}
.social-icon-team .list-group-item{
  font-size: 14px;
  color: var(--primary);
  border-radius: 0;   
  margin: 0 7px;
  padding: 0;
  text-align: center;
  border: none;
}
.social-icon-team .list-group-item i{
  font-size: 20px;
}
.social-icon-team .list-group-item:hover{
  color: var(--black);
  background: transparent;
  
}
.social-icon-team {
  flex-direction: row;
  padding: 10px;
  
  align-items: center;
  background-color: var(--white);
  max-width: 170px;
  border-radius: 46px;
  justify-content: center;
  margin: 0 auto -40px;

} 

.partners .row {
  align-items: center;
}

.partners .row img {
  padding-bottom: 20px;
  display: block;
  margin: auto;
  max-width: min(100%, 247px);
}
/* ourportfolio separate CSS file Name Tdslightbox.css   */
.ourportfolio {
  background: var(--bggrey);
}
/* Marketing Section Start css*/
.marketing-section{
  background: var(--primary);
}
.marketing-section .media-left {
 
  width: 70px;
  background: var(--white);
  height: 70px;
  text-align: center;
  line-height: 70px;
  color:var(--primary);
  border: 1px solid var(--white);
  border-radius: 50%;
  font-size: 30px;
  transition: .4s;
  margin-right: 15px;
}
.markrt-box{
  margin-bottom: 35px;
}
.markrt-box:last-child{
  margin-bottom: 0;
}
.markrt-box .media-heading{
  font-size: 20px;
  font-weight: 500;
  color: var(--white);
  margin: 0 0 15px;
  
 
  
}
.markrt-box .media-body{
 
  font-size: 16px;
  color: var(--white);
  line-height: 1.5;
  margin: 0 0 0px;

}
/* Expert Feadback Section Start separate CSS file Name Tdsfeedbackslider.css  */
/* Our Latest Pricing Section Start separate CSS file Name Tdspricingtable.css  */
/* Our Latest News  Section Start css*/
.latestnews{
  background: var(--bggrey);
}
.card-title {
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
  color: #000;
  margin:5px 0 20px;


}
.card.card-body{
  border: none;
}
/* Work With Us  Section Start css */
.workwithus{
  background: var(--primary);
}
/* Contact Us Section Start css*/
.address-box {
  box-shadow: 7px 5px 30px 0 rgba(72,73,121,.15);
  padding: 25px 25px 25px 105px;
  position: relative;
}
.contactus {
  background: var(--bggrey);
}
.contactus h4{
  margin-top: 20px;
  line-height: 1;
}
.address-box .media-left {
  font-size: 25px;
  position: absolute;
  left: 25px;
  top: 50%;
  width: 50px;
  height: 50px;
  line-height: 49px;
  text-align: center;
  border-radius: 50%;
  color: var(--primary);
  border: 1px solid var(--primary);
  transition: .4s;
  transform: translateY(-50%);
}
.address-box:hover .media-left{
  color: var(--white);
  background-color:var(--primary);
}
.address-box .media-body{
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  line-height: 22px;

}
.address-box .media-body h4{
  line-height: 25px;
  margin-top: 0px;
  font-size: 18px;
}
.address-box p{
  margin-bottom: 0;
}
.address-box a{
  margin: 0;
  line-height: 20px;
  font-weight: 400;
  color:var(--light);
}
.address-box a:hover{
  color:var(--primary);
}
.social-icon .list-group-item{
  width: 50px;
  height: 50px;
  border: 1px solid var(--primary);
  background: var(--primary);
  color: var(--white);
  border-radius: 100%;  
  line-height: 50px;
  margin-right: 10px;
  padding: 0;
  text-align: center;
}
.social-icon .list-group-item:hover{
  background: transparent;
  color: var(--primary);
}
.social-icon.list-group {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  align-items: center;
}
.social-icon .list-group-item:last-child, .social-icon .list-group-item:first-child{ border-radius: 100%;}
.form-control {
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: 50px;
  padding: 0.375rem 0.75rem; 
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid var(--ingrey);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.contact-form {
  display: none;
}
.form-control:focus {
  color: #495057;  
  border-color: var(--ingrey);
  outline: 0;
  box-shadow: none
}

textarea.form-control {
  height: 90px;
  resize: none;
}
textarea.form-control {
  height: 110px;
}
.btn-primary {
  color:var(--white);
  background-color: var(--primary);;
  border:1px solid var(--primary);
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle  {
  color: var(--primary);
  background-color: var(--white);
  border:1px solid var(--primary);
  outline: none;
  box-shadow: none !important;
  
}
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus{
  color: var(--white);
  background-color: var(--black);
  box-shadow: none !important;
  
  
}
/*Footer  Section Start separate CSS file Name Tdsfooter.css  */