
/* Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
  .why-box h5 {
    font-size: 12px;
    font-weight: 500;       
}
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

  .banner-img{
      height: auto;
      min-height: 600px;
  }
  nav.fixed-top{
    padding:12px 15px;
  }
  h1{
      font-size: 32px;
      line-height: 1.3;
  }
  h4{
    font-size: 24px;
  }
  p{
    font-size: 14px;
  }
  .banner-text p{
      font-size: 16px;
      line-height: 1.4;
  }
  .banner .banner-owl .owl-nav .owl-prev{
      width: 25px;
      left:15px;
  }
  .banner .banner-owl .owl-nav .owl-next{
    width: 25px;
    right:15px;
      
  }
  .wel-box, .team-box, .card, .marketing-img, .about-img, .portfolio-box{
    margin-bottom: 40px;
  }
 .card.card-body{
    
    margin-bottom: 0;
 }
  .team-img{
    width: 100px;
    height: 100px;
  }
  .grapping {
    padding: 65px 0;
  }
  .title-box{
    margin-bottom: 40px;
  }
  .title-box h2 {
    font-size: 22px;
    
    margin-bottom: 14px;
  }
  footer p, .footer-menu a{
    font-size: 14px;
    text-align: center;
  }
  .footer-menu{
    justify-content: center;
  }
  .footer-menu a:first-child{
    padding-left: 0px;
  }
  .contactus .row .col-md-12:first-child .address-box{
    margin-bottom: 30px;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
  }
  a.navbar-brand{
    font-size: 1.8rem;
  }
  .coman-box h4{
    font-size: 18px;
    margin: 10px 0;
  }
  .marketing-img img, .about-img img{
    width: 100%;
  }
  .card-body{
    padding: 1rem;
  }
  .card-title{
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 12px;
  }
  .title-box p{
    font-size: 15px;
  }
  .pricing-features li {
    font-size: 14px;

    margin-bottom: 13px;
  }
  .btn{
    font-size: 14px;
    padding: 8px 26px;
  }
  .white-bg{
    padding: 20px;
  }
  .modal-video .close{
    right: 0;
  }
  iframe {
    height: auto;
    min-height: 450px;
}
}
/* Medium devices (tablets, 768px and up) */
 @media (max-width: 767.98px) {

  .banner-img{
      height: auto;
      min-height: 600px;
  }
  h1{
      font-size: 24px;
  }
  h4 {
    font-size: 18px;
  }
  .banner-text p{
      font-size: 16px;
      line-height: 1.3;
  }
  .banner .banner-owl .owl-nav .owl-prev{
      width: 25px;
      left:15px;
  }
  .banner .banner-owl .owl-nav .owl-next{
    width: 25px;
    right:15px;
      
  }
  .wel-box, .team-box, .card, .marketing-img, .about-img, .portfolio-box{
    margin-bottom: 40px;
  }
 .card.card-body{
    
    margin-bottom: 0;
  }
  .team-img{
    width: 100px;
    height: 100px;
  }
  .grapping {
    padding: 65px 0;
  }
  .title-box{
    margin-bottom: 40px;
  }
  .title-box h2 {
    font-size: 22px;
    
    margin-bottom: 14px;
  }
  .navbar-nav .nav-link, nav a{
    color: var(--black);
  }
  footer p, .footer-menu a{
    font-size: 14px;
    text-align: center;
  }
  .footer-menu{
    justify-content: center;
  }
  .footer-menu a:first-child{
    padding-left: 0px;
  }
  .portfolio-box img{
    width: 100%;
  }
  .ourportfolio .row .col-md-4:nth-child(4) .portfolio-box, .ourportfolio .row .col-md-4:nth-child(5) .portfolio-box, .ourportfolio .row .col-md-4:nth-child(6) .portfolio-box{
    margin-bottom: 30px;
  }
  .pricing-table{
    margin-bottom: 30px;
  }
  .tab-pane .row [class*="col-"]:last-child  .pricing-table{
    margin-bottom: 0;
  }
  .banner-text .btn{
    font-size: 14px;
    padding: 10px 20px;
  }
  .banner-text{
    padding:  0 15px 30px 15px;
  }
  .why-choose .col-md-4:nth-child(2) .why-box  {
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
}
.why-choose .col-md-4:nth-child(3) .why-box  {
  border-bottom: 1px solid #eee;
  border-right: 1px solid transparent;
  border-left: 1px solid #eee;
}
.why-choose .col-md-4:nth-child(4) .why-box  {
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
}
.owl-theme .owl-nav.disabled + .owl-dots{
  margin-top: 30px;
}
.markrt-box .media-heading {
  font-size: 18px;
  
  margin: 0 0 10px;
}
.markrt-box .media-body{
  font-size: 14px;
}
.address-box .media-body h4{
  font-size: 16px;
}

}
 /* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575px) {
  h1{
    font-size: 22px;
  }
  .banner-text p{
    font-size: 14px;
  }
  iframe {
    height: auto;
    min-height: 250px;
}
.modal-body{
  padding: 0.5rem;
}
  
}