/* color css */
:root {  
    --orange:#f6994b;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --tertiary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;    
    --black:#000000;    
    --bggrey:#f9f9f9;
    --lightgrey:#6b6b6b;
    --ingrey:#ced4da;
    --ingrey:#ced4da;
    --light:#999999;
    --primary:#163156;
    --secondary:#4A9FA5;
    --teal:#5EC4CA
}
